<template>
  <div >
    <POSPrinter />
  </div>
</template>

<script>
import POSPrinter from '../cards/POSPrinter'

export default {
  name: 'App',
  components: {
    POSPrinter
  }
}
</script>