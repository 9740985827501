<template>
  <div>
    <div class="row d-flex align-items-center">
      <div class="col-xl-auto col-lg-auto col-md-auto col-4 border text-center">
        <button
          @click="refersh()"
          class="btn btn-secondary d-flex justify-content-center align-items-center"
        >
          <i class="bi bi-x-octagon fs-4"></i>
          <span class="m-2"> Actualiser </span>
        </button>
      </div>

      <div class="col-xl-auto col-lg-auto col-md-auto col-4 border text-center">
        <button
          @click="$router.push({ name: 'layout' })"
          class="btn btn-warning d-flex justify-content-center align-items-center"
        >
          <i class="bi bi-box fs-4"></i>
          <span class="m-2"> Magasinier </span>
        </button>
      </div>

      <div class="col-xl-auto col-lg-auto col-md-auto col-4 border text-center">
        <button
          @click="logout()"
          class="btn btn-danger d-flex justify-content-center align-items-center"
        >
          <i class="bi bi-x-octagon fs-4"></i>
          <span class="m-2"> Éteindre </span>
        </button>
      </div>
    </div>
    <br />
    <br />
    <div class="row justify-content-center">
      <div class="col-auto">
        <button
          @click="printReceipt"
          class="btn btn-primary p-3 d-flex align-items-center"
        >
          <i class="bi bi-printer fs-1 px-2"></i> Print Receipt
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ThermalPrinter from "node-thermal-printer";

export default {
  name: "POSPrinter",
  methods: {
    printReceipt() {
      const printer = new ThermalPrinter({
        type: "network", // printer type: serial|usb|network
        interface: "printer_name", // printer interface
        driver: "WindowsPrinter", // printer driver: WindowsPrinter or EscPos
      });

      printer
        .alignCenter()
        .setTypeFontB()
        .println("My Store")
        .setTypeFontA()
        .println("123 Main St, Anytown USA")
        .println("(555) 555-5555")
        .feed(1)
        .cut()
        .execute((err) => {
          if (err) {
            console.error("Failed to print receipt:", err);
          } else {
            console.log("Receipt printed successfully!");
          }
        });
    },
  },
};
</script>
